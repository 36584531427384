import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { inject } from 'aurelia-dependency-injection';

interface EventWrapper {
    sub: Subscription;
    event: string;
    handler: any;
}

@inject(EventAggregator)
export class EventAggregatorWrapper {
    private listeners: EventWrapper[] = [];
    constructor(private ea: EventAggregator) { }

    on(event: any, handler) {
        this.listeners.push({
            event,
            handler,
            sub: this.ea.subscribe(event, handler),
        });
    }

    off(event: any, handler) {
        for (let i = 0; i < this.listeners.length; i++) {
            const item = this.listeners[i];

            if (event === item.event && handler === item.handler) {
                this.listeners.splice(i, 1);
                item.sub.dispose();
            }
        }
    }
}
