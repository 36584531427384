import { IFramePage } from 'app';
import { LogManager } from 'aurelia-framework';
import { AlertService } from '../../services/util/Alert';
const log = LogManager.getLogger('dfp:iframe');

export function onIframeEventMessageHandler(event) {

    log.info('Message posted: ', event.data);

    let data = event.data;
    if (typeof data === 'string') {
        try {
            data = JSON.parse(data);
        } catch (e) {
            log.warn('Invalid data', data);
            return;
        }
    }

    if (data.type === 'before-save' || data.enketoEvent === 'submissionsuccess') {
        const formUrl = data.location.pathname;
        (this.alerts as AlertService).create({
            label: 'Form saved. Would you like to hide the form?',
            timeout: 10000,
            actions: [{
                id: 'hide form',
                label: 'Yes, Hide form',
                formUrl,
                viewModel: this,
                execute({ formUrl, viewModel }) {
                    if (viewModel.activeIframe?.src.indexOf(formUrl) > -1) {
                        const iframe: IFramePage = viewModel.activeIframe;
                        iframe.title = iframe.title + ' (saved)';
                        iframe.saved = true;

                        // automatically remove the iframe after 5 minutes
                        setTimeout(() => {
                            const index = viewModel.iframePages.indexOf(iframe);
                            viewModel.iframePages.splice(index, 1);
                        }, 60 * 1000 * 5);

                        // set active
                        viewModel.activeIframe = null;
                    }
                },
            }],
        });

    }

    if (event.data?.name == 'iframe-navigation') {
        const location: Location = event.data.location;
        if (location.pathname.match(/thanks/)) {
            this.iframeActive = false;
        }

    }
}
