import { ProgramAssetType } from '@wsb_dev/datafi-shared/lib/types/ProgramAssetType';
import { ValidationRules } from 'aurelia-validation';

export interface ValidatedProgramAssetType extends ProgramAssetType {}
export class ValidatedProgramAssetType  {
    constructor(props: Partial<ProgramAssetType>){
        if(!props.assetSchema){
            props.assetSchema = [];
        }
        Object.assign(this, props);
    }
}

ValidationRules
    .ensure('title').required()
    .ensure('description').required()
    .ensure('type').required();
