import { ValidationRules } from 'aurelia-validation';
import {Role as BaseRole} from '@wsb_dev/datafi-shared/lib/types/Roles';
import { Organization } from '@wsb_dev/datafi-shared/lib/types';

export interface ValidatedOrganization extends Organization {}
export class ValidatedOrganization  {
    constructor(opt: Organization){
        Object.assign(this, opt);
    }
}

ValidationRules
    .ensure('name').required()
    .on(ValidatedOrganization);