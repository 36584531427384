import debounce from 'p-debounce';

export function debounced(duration: number, opt?: debounce.Options) {
    return function innerDecorator(target: any, key: string, descriptor: any): any {
        return {
            configurable: true,
            enumerable: descriptor.enumerable,
            get: function getter() {
                // Attach this function to the instance (not the class)
                Object.defineProperty(this, key, {
                    configurable: true,
                    enumerable: descriptor.enumerable,
                    value: debounce(descriptor.value, duration, opt),
                });

                return this[key];
            },
        };
    };
}
