
export function isValid(value: string|number): boolean {
    const date = new Date(value);
    if (date.toString() === 'Invalid Date') {
        return false;
    }

    const dateString = ('' + value).replace(/\s/g, '');
    const re = /^\d+[-/]\d+[-/]\d+/;
    return re.test(dateString);
}
