export function serialize(data: any) {
    if (Array.isArray(data)) {
        return data
            .map(serialize)
            .filter((item) => !(typeof item === 'undefined' || item === null));
    }

    if (!data) {
        return;
    }

    if (typeof data === 'object') {
        const newData = {};
        Object.keys(data).forEach((k) => {
            if (!k.startsWith('__')) {
                newData[k] = data[k];
            }
        });
        return newData;
    }

    return data;
}
