export function deepEqual(obj1: Record<string, any>, obj2: Record<string, any>){
    if(!obj1 || !obj2){
        return obj1 === obj2;
    }
    const keys =   Object.keys(obj1)
        .filter((key) => !key.startsWith('_'));

    for(const key of keys){

        if(Array.isArray(obj1[key])){
            for(let i = 0; i < obj1[key].length; i ++){
                if(!deepEqual(obj1[key][i], obj2[key]?.[i])){
                    return false;
                }
            }
        } else if(typeof obj1[key] === 'object'){
            if(!deepEqual(obj1[key], obj2[key])){
                return false;
            }
        } else if(obj1[key] !== obj2[key]){
            return false;
        }
    }

    return true;
}
