import { FormSubmission } from '@wsb_dev/datafi-shared/lib/types/FormSubmission';
import { ValidationRules } from 'aurelia-validation';

export interface ValidatedFormSubmission extends FormSubmission {}

export class ValidatedFormSubmission  {
    constructor(params: Partial<FormSubmission>){
        Object.assign(this, params);

        if(!this.metadata){
            this.metadata = {};
        }

        if(!this.attachments){
            this.attachments = [];
        }
    }
}

ValidationRules
    .ensure('program_id').required()
    .ensure('survey_id').required()
    .ensure('organizationId').required()
    .on(ValidatedFormSubmission);
