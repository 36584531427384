import { ValidationRules } from 'aurelia-validation';
import {Project as BaseProject} from '@wsb_dev/datafi-shared/lib/types/Project';

export interface ValidatedProject extends BaseProject {
    _pinned?: boolean;
    _recent?: boolean;
}
export class ValidatedProject {
    constructor(project: Partial<ValidatedProject>) {
        if (!project.metadata) {
            project.metadata = {};
        }
        Object.assign(this, project);
    }
}

export const projectFilters = [
    {
        type: 'checkbox', name: 'status', label: 'Status', options:
            [{ label: 'Open', value: 'Open' }, {label: 'Closed', value: 'Closed' }],
    },
    { type: 'text', name: 'created_by', label: 'Creator Email' },
    { type: 'text', name: 'project_name', label: 'Project Name' },
    { type: 'date', name: 'modified_at', label: 'Modified at' },
    {type: 'number', name: 'program_id', label: 'Program ID', visibility: {list: false}},
];

ValidationRules
    .ensure('project_name').required().withMessage('Project name is required')
    .ensure('status').required().withMessage('Status is required')
    .on(ValidatedProject);
