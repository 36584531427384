import { ValidationRules } from 'aurelia-validation';
import {Credentials as BaseCredentials} from '@wsb_dev/datafi-shared/lib/types/Credentials';

export interface ValidatedCredentials extends BaseCredentials{}
export class ValidatedCredentials  {
    constructor(props: Partial<BaseCredentials>){
        Object.assign(this, props);
    }
}

ValidationRules
    .ensure('username').required()
    .ensure('password').required().minLength(8)
    .on(ValidatedCredentials);
