import { deepEqual } from '../util/objects/deepEqual';

export class DirtyChecker {
    public dirtyProps: string[];
    public getDirtyWarningMessage = (key: string): string => `Changes to ${key} will be lost, would you like to continue?`
    private _clones: Record<string, any>;

    updateClones(){
        this._clones = {};
        this.dirtyProps.forEach((key) => {
            this._clones[key] = JSON.parse(JSON.stringify(this[key]));
        });
    }

    canDeactivate() {
        for(const key of this.dirtyProps){
            if(!deepEqual(this[key], this._clones[key])){
                return confirm( this.getDirtyWarningMessage(key) );
            }
        }

        return true;
    }
}
