import pThrottle, { Options } from './p-throttle/index';

export function throttled(opt?: Partial<Options>) {

    const throttle = pThrottle({
        limit: 1,
        interval: 1000,
        ...opt,
    });

    return function innerDecorator(target: any, key: string, descriptor: any): any {
        return {
            configurable: true,
            enumerable: descriptor.enumerable,
            get: function getter() {
                // Attach this function to the instance (not the class)
                Object.defineProperty(this, key, {
                    configurable: true,
                    enumerable: descriptor.enumerable,
                    value: throttle(descriptor.value),
                });

                return this[key];
            },
        };
    };
}
