import { Field } from '@wsb_dev/datafi-shared/lib/types';

export const programUserRoleFields: Field[] = [
    {name: 'createdAt', type: 'date'},
    {name: 'updatedAt', type: 'date'},
    // {name: 'createdBy', type: 'text'},
    // {name: 'updatedBy', type: 'text'},
    {name: 'program_id', type: 'number'},
    {name: 'user_id', type: 'number'},
    {name: 'role_id', type: 'number'},
    {name: 'metadata', type: 'object'},
    {name: 'user', type: 'object', fields: [
        {name: 'createdAt', type: 'date'},
        {name: 'updatedAt', type: 'date'},
        {name: 'email', type: 'text'},
        {name: 'username', type: 'text'},
        {name: 'fullname', type: 'text', label: 'Full Name'},
    ]},
];
