import { ValidationRules } from 'aurelia-validation';
import { ProgramSurvey } from './ProgramSurvey';
import { LogManager } from 'aurelia-framework';
import {Program as BaseProgram} from '@wsb_dev/datafi-shared/lib/types/Program';
import { getFields } from '@wsb_dev/datafi-shared/lib/util/fields/getFields';
import { User } from '@wsb_dev/datafi-shared/lib/types/Users';
import { ObjectWithFiles } from './ObjectWithFile';
import { FileService } from '../services/api/file';
import { ValidatedAssetType } from './AssetType';

const log = LogManager.getLogger('dfp:program');

export interface Program extends BaseProgram {}

export class Program extends ObjectWithFiles {
    constructor(data: Partial<Program>, fileService: FileService) {
        super(fileService);
        if (!data.roles) {
            data.roles = [];
        }

        if (data.projectSchema) {
            data.projectSchema = getFields(data.projectSchema);
        } else {
            data.projectSchema = [];
        }

        if (!data.projectQuery) {
            data.projectQuery = {};
        }

        if (data.surveys) {
            data.surveys = data.surveys.map((s) => new ProgramSurvey(s));
        }

        if(data.assetTypes){
            data.assetTypes = data.assetTypes.map((t) => new ValidatedAssetType(t));
        }
        Object.assign(this, data);
    }

    getThumbnail(){
        return this.getFile('thumbnailUrl', 'thumbnail');
    }

    hasUserRole(user: User, ...roles: string[] | string[][]) {
        log.debug('program.hasUserRole: ', user, roles);

        if (!user) {
            return false;
        }

        if (user.roles?.find((r) => r.role.role_name === 'admin')) {
            log.debug('program.hasUserRole: admin');
            return true;
        }

        // if an array was passed rather than parameters
        if (Array.isArray(roles[0])) {
            roles = roles[0];
        }

        for (const roleName of roles) {
            const validRole = this.roles.find((role) => {
                return role.user_id === user.id &&
                    role.role.role_name === roleName;
            });
            if (validRole) {
                log.debug('program.hasUserRole: valid role: ', validRole.role.role_name);
                return true;
            }
        }
        log.debug('program.hasUserRole: no valid role found');

        return false;
    }
}

ValidationRules
    .ensure('title').required()
    .ensure('description').required()
    .ensure('projectSchema').displayName('Project Schema').satisfiesRule('json')
    .on(Program);
