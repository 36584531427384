/**
 * A function for ensuring getters are only called a certain frequency
 *
 * @url https://github.com/aurelia-contrib/aurelia-getter-throttle/blob/master/index.js
 *
 * @param delay The timeout delay between getter calls
 */
export function getterThrottle(delay: number) {
    if (!delay) delay = 0;

    return function(target, key, descriptor) {
        const dependencies = descriptor.get.dependencies;
        const originalGetter = descriptor.get;
        const innerPropertyName = '__au_gt_' + key;

        const clearInnerProperty = function() {
            delete this[innerPropertyName];
        };

        descriptor.get = function() {
            if (this.hasOwnProperty(innerPropertyName)) {
                return this[innerPropertyName];
            }

            Object.defineProperty(this, innerPropertyName, {
                value: originalGetter.call(this),
                configurable: true,
                enumerable: false,
                writable: true,
            });

            setTimeout(clearInnerProperty.bind(this), delay);

            return this[innerPropertyName];
        };

        if (dependencies) {
            descriptor.get.dependencies = dependencies;
            delete originalGetter.dependencies;
        }

        return descriptor;
    };
}
