export function createCustomEvent<T>(evtType: string, evtData: T, shouldBubble = false): CustomEvent<T>{

    let evt: CustomEvent<T>;
    if (typeof CustomEvent === 'function') {
        evt = new CustomEvent<T>(evtType, {
            bubbles: shouldBubble,
            detail: evtData,
        });
    } else {
        evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(evtType, shouldBubble, false, evtData);
    }

    return evt;

}
