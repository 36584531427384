import { ValidationRules } from 'aurelia-validation';
import { ProgramWebhook} from '@wsb_dev/datafi-shared/lib/types/ProgramWebhook';

export interface ValidatedWebhook extends ProgramWebhook{}
export class ValidatedWebhook  {
    constructor(props: Partial<ProgramWebhook>){
        Object.assign(this, props);
    }
}

ValidationRules
    .ensure('url').required().matches(/https?:\/\/.+/).withMessage('URL must be a valid http or https url')
    .ensure('label').required().minLength(10)
    .on(ValidatedWebhook);
