
import {Secret as BaseSecret} from '@wsb_dev/datafi-shared/lib/types/Secret';
import { ValidationRules } from 'aurelia-validation';

export interface ValidatedSecret extends BaseSecret {}

export class ValidatedSecret implements BaseSecret {
    constructor(opt: BaseSecret){
        Object.assign(this, opt);
    }
}

ValidationRules
    .ensure('user_id').required()
    .ensure('url').required()
    .ensure('username').required()
    .ensure('secret').required()
    .on(ValidatedSecret);
