import { LogManager } from 'aurelia-framework';
import { isPrimitive } from '../objects/isPrimitive';
const log = LogManager.getLogger('dfp:array');

export function ensureArray<T>(items: unknown): T[] {
    if (!items) {
        return [];
    }

    if (isPrimitive(items)) {
        return [items] as T[];
    }

    if (Array.isArray(items) || (items as []).length) {
        return Array.from(items as T[]);
    }

    if (typeof items === 'object') {
        // if array-like object with .0, .1, .2...
        if (items[0]) {
            return Object.keys(items).map((i) => items[i]);
        }
        return [items] as any[];
    }

    log.warn('ensureArray: could not determine data type', items);
    return [];
}
