import { ProgramConfig } from '@wsb_dev/datafi-shared/lib/types/ProgramConfig';
import { ValidationRules } from 'aurelia-validation';

export interface ValidatedProgramConfig extends ProgramConfig { }
export class ValidatedProgramConfig {
    constructor(props: Partial<ProgramConfig>) {
        Object.assign(this, props);
        if(!this.scopes){
            this.scopes = 'program';
        }
    }
}

ValidationRules
    .ensure('type').required()
    .on(ValidatedProgramConfig);
