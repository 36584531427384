export function ensureNumber(item: any) : number {
    if (!item) {
        return 0;
    }
    return typeof item === 'number' ?
        item :
        typeof item === 'string' ?
            parseFloat(item) :
            0;

}
