import { Paginated, Service } from '@feathersjs/feathers';
import { LogManager } from 'aurelia-framework';

const log = LogManager.getLogger('dfp:recursiveQuery');

/**
 * This function will query a service recursively until it reaches
 * the last page of results. It will then merge all the results
 * into one final result array.
 *
 * @param service Service to query
 * @param query Query parameters - see https://docs.feathersjs.com/api/databases/querying.html#equality
 * @param result result array - generally just set this to an empty array unless you already have other data you want to merge
 * @returns Promise with ALL the data,
 */
export function recursiveQuery<T>(service: Service<T>, query: Record<string, unknown>, result = []) : Promise<any[]> {
    log.debug('Fetching page $skip:' + query.$skip);
    return service.find({
        query,
    }).then((page: Paginated<T>) => {
        result = result.concat(page.data);
        if (result.length < page.total) {
            return recursiveQuery(
                service,
                {
                    ...query,
                    $skip: result.length,
                }, result);
        }
        return result;
    });
}
