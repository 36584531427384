import { ValidationRules } from 'aurelia-validation';
import { ValidatedUser } from './Users';

export class EditableUser extends ValidatedUser  {
    id?: number;
    email?: string;
    fullname?: string;
    oldPassword?: string;
    password?: string;
    confirmPassword?: string;
}

ValidationRules
    .ensure('email').required().matches(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/)
    .ensure('fullname').displayName('Full name').required()
    .ensure('password').satisfiesRule('matchesProperty', 'confirmPassword')
    .ensure('confirmPassword').displayName('Confirm password').satisfiesRule('matchesProperty', 'password')
    .on(EditableUser);
