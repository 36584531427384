import * as d3 from 'd3-interpolate';
import * as d3scales from 'd3-scale-chromatic';

type RGB = `rgb(${number}, ${number}, ${number})`;

export interface ColorTheme {
    label: string,
    type: 'Sequential' | 'Divergent',
    colors: RGB[],
    d3scale?: any
}

export const colorThemes: ColorTheme[] = [
    {label: 'Orange', type: 'Sequential', colors: ['rgb(255, 255, 229)', 'rgb(254, 206, 102)', 'rgb(225, 100, 14)'], d3scale: d3scales.interpolateYlOrBr},
    {label: 'Slate', type: 'Sequential', colors: ['rgb(66, 64, 61)', 'rgb(108, 104, 100)', 'rgb(169, 161, 156)', 'rgb(231, 229, 228)']},
    {label: 'Cool', type: 'Sequential', colors: ['rgb(174, 233, 232)', 'rgb(83, 139, 160)', 'rgb(66, 82, 122)']},

    {label: 'Blue Red', type: 'Divergent', colors: ['rgb(66, 82, 122)',  'rgb(227, 199, 199)', 'rgb(255, 95, 51)']},
    {label: 'Brown Teal', type: 'Divergent', colors: ['rgb(84, 48, 5)', 'rgb(230, 205, 148)', 'rgb(151, 213, 204)'], d3scale: d3scales.interpolateBrBG},
    {label: 'Purple Green', type: 'Divergent', colors: ['rgb(64, 0, 75)', 'rgb(206, 180, 215)', 'rgb(182, 225, 176)'], d3scale: d3scales.interpolatePRGn},
];

export function getColorTheme (dataLength: number, theme: ColorTheme): {primary: RGB[], accent: RGB[]} {
    theme = colorThemes.find((t) => (t.label === theme.label));
    const stepInterval = 1 / dataLength;
    const colors = [];

    let step = 0;
    // run the color interpolation
    while (step <= 1 && colors.length + 1 <= dataLength) {
        if (theme.d3scale) {colors.push(theme.d3scale(step));}
        else {colors.push(d3.interpolateRgbBasis(theme.colors)(step));}
        step += stepInterval;
    }

    const accentColors = colors.map((x, i) => {
        if (theme.type === 'Sequential') {
            // get opposite color if there is only one data point
            if (dataLength === 1) {
                if (theme.d3scale) { return theme.d3scale(1); }
                else { return d3.interpolateRgbBasis(theme.colors)(1); }
            }
            if (i > (colors.length - 1) / 2) {return colors[0];} else { return colors[colors.length - 1];}
        } else { return 'rgb(255, 255, 255)'; }
    });

    return {primary: colors, accent: accentColors};
}
