import { ValidationRules } from 'aurelia-validation';
import {Role as BaseRole} from '@wsb_dev/datafi-shared/lib/types/Roles';

export interface ValidatedRole extends BaseRole {}
export class ValidatedRole  {
    constructor(opt: BaseRole){
        Object.assign(this, opt);
    }
}

ValidationRules
    .ensure('role_name').required().minLength(4)
    .ensure('description').required().minLength(10)
    .on(ValidatedRole);
