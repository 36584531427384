import { ProgramAssetType } from '@wsb_dev/datafi-shared/lib/types';

export interface ValidatedAssetType extends ProgramAssetType {
    __visible: boolean;
}

export class ValidatedAssetType {
    constructor(params: Partial<ProgramAssetType>){
        params.__visible = false;
        Object.assign(this, params);
    }
}
