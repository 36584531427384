import { LogManager } from 'aurelia-framework';
import { EventEmitter } from 'events';
import get from 'lodash.get';
const log = LogManager.getLogger('dfp:subscribe');

export interface SubscribeOptions {
    /**
     * Array of events to subscribe to
     */
    events: Subscriber[];
    /**
     * Method name called when you want subscribe
     * to start
     * listening for events
     * Default: 'attached'
     */
    attached?: string;
    /**
     * Method name called when you want subscribe
     * to stop
     * listening for events
     */
    detached?: string;
    /**
     * Event emitter subscribe function
     */
    on?: string;
    /**
     * Event emitter unsubscribe function
     */
    off?: string;
}

export interface Subscriber {
    eventEmitter: string;
    fn: string;
    event: string|any;
    _handler?: any;
}

const defaults: Partial<SubscribeOptions> = {
    attached: 'attached',
    detached: 'detached',
    on: 'on',
    off: 'off',
};

export function subscribe(opts: SubscribeOptions) {
    opts = {
        ...defaults,
        ...opts,
    };

    return function (target: any) {
        const originalAttached = target.prototype[opts.attached];
        const originalDetached = target.prototype[opts.detached];

        log.debug(`Overriding methods: ${opts.attached}, ${opts.detached}`);

        target.prototype[opts.attached] = function attached(...args) {
            opts.events.forEach((ev) => {
                log.debug('subscribe on: ', ev.eventEmitter, ev.event);
                const eventEmitter = get(this, ev.eventEmitter) as EventEmitter;
                if (!eventEmitter) {
                    log.warn(`Missing property: ${ev.eventEmitter}`, this);
                    return;
                }

                if(ev._handler){
                    log.warn(`Already subscribed: ${ev.eventEmitter}`, this);
                }

                ev._handler = (data) => {
                    log.debug('subscribe emit: ', ev.event, data);
                    this[ev.fn](data);
                };
                if(Array.isArray(eventEmitter)){
                    eventEmitter.forEach((item) => {
                        item[opts.on](ev.event, ev._handler);
                    });
                } else {
                    eventEmitter[opts.on](ev.event, ev._handler);
                }
            });

            if (originalAttached) {
                return originalAttached.apply(this, args);
            }
        };

        target.prototype[opts.detached] = function detached(...args) {
            opts.events.forEach((ev) => {
                log.debug('subscribe off: ', ev.eventEmitter, ev.event);
                const eventEmitter = get(this, ev.eventEmitter) as EventEmitter;

                // check for if the event emitter is not set
                if (!eventEmitter) {
                    log.warn(`Missing property: ${ev.eventEmitter}`, this);
                    return;
                }

                // check if we called detached without attaching
                if (!ev._handler) {
                    log.debug(`Unsubscribe ${ev.eventEmitter}:${ev.event} skipped, handler not subscribed`);
                    return;
                }

                if(Array.isArray(eventEmitter)){
                    eventEmitter.forEach((item) => {
                        item[opts.off](ev.event, ev._handler);
                    });
                } else {
                    eventEmitter[opts.off](ev.event, ev._handler);
                }
            });
            if (originalDetached) {
                return originalDetached.apply(this, args);
            }
        };
    };

}
